<template>
  <div class="pa-5">
    <v-card class="mx-auto">
      <v-card-title>
        <h2 v-t="'Sections.SectionsManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$router.push('Sections/new')">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('Sections.AddSection') }}
        </v-btn>
      </v-card-title>
      <div class="table-container">
        <div class="header-row">
          <div class="little-space-teehee"></div>
          <div class="table-element drag"></div>
          <div v-t="'General.PublishingStatus'" class="table-element"></div>
          <div v-t="'Sections.SectionName'" class="table-element"></div>
          <div v-t="'General.Actions'" class="table-element"></div>
        </div>
        <vuetify-draggable-treeview v-model="sections" @input="updateIndexes">
          <template v-slot:prepend="{ item }">
            <div class="table-element drag">
              <div class="table-element-center">
                <v-icon style="padding: 10px; cursor: move">mdi-cursor-move</v-icon>
              </div>
            </div>
            <div class="table-element">
              <div class="table-element-center">
                <v-switch @change="togglePublished(item)" v-model="item.published"></v-switch>
              </div>
            </div>
          </template>
          <template class="table-element" v-slot:append="{ item }">
            <div class="table-element">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="secondary" icon dark @click="$router.push('Sections/' + item.id)" @click.stop="">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Edit'"></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="red" icon @click="deleteSection(item)" @click.stop="">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Delete'"></span>
              </v-tooltip>
            </div>
          </template>
        </vuetify-draggable-treeview>
      </div>
    </v-card>
  </div>
</template>

<script>
import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'

export default {
  name: 'Sections',
  components: { VuetifyDraggableTreeview },
  data () {
    return {
      valid: false,
      sectionFormVisible: false,
      sections: []
    }
  },
  created () {
    this.getSections()
  },
  methods: {
    async updateIndexes (sections) {
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].index !== i) {
          sections[i].index = i
          await this.$store.dispatch('editSection', sections[i])
          const children = sections[i].children
          if (children) {
            for (let j = 0; j < children.length; j++) {
              if (children[j].index !== j) {
                children[j].index = j
                await this.$store.dispatch('editSection', children[j])
              }
            }
          }
        }
      }
    },
    deleteSection (section) {
      var removable = true
      if (this.sections.find((sect) => {
        return sect.idParent === section.id
      })) {
        removable = false
      }

      var promises = []

      promises.push(this.$store.dispatch('getAllThemes').then(() => {
        var themes = this.$store.getters['GET_THEMES']()
        if (themes.find((theme) => {
          return theme.sectionList.find((sectionidtheme) => {
            return sectionidtheme === section.id
          })
        })) {
          removable = false
        }
      }))

      promises.push(this.$store.dispatch('getAllDecos').then(() => {
        var decos = this.$store.getters['GET_DECOS']()
        if (decos.find((deco) => {
          return deco.sectionList.find((sectioniddeco) => {
            return sectioniddeco === section.id
          })
        })) {
          removable = false
        }
      }))

      Promise.all(promises).then(() => {
        if (removable) {
          this.$store.dispatch('deleteSection', section.id).then(async () => {
            await this.getSections()
            this.updateIndexes(this.sections)
            this.$emit('notify', {
              color: 'green',
              text: this.$t('Messages.SectionDeleted')
            })
          }).catch((err) => {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('Messages.' + err.response.data)
            })
          })
        } else {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.CannotDeleteSection')
          })
        }
      })
    },
    async getSections () {
      await this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']()
        this.initSections()
      })
    },
    initSections () {
      this.sections.forEach((section) => {
        section.children = this.sections.filter((sec) => {
          return sec.idParent === section.id
        })
        section.children.sort((a, b) => { return a.index - b.index })
      })
      for (let i = this.sections.length - 1; i > -1; i--) {
        if (this.sections[i].idParent && this.sections[i].idParent !== null) {
          this.sections.splice(i, 1)
        }
      }
      this.sections.sort((a, b) => { return a.index - b.index })
    },
    togglePublished (section) {
      this.$store.dispatch('editPublishedSection', section).then(res => {
        this.getSections()
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    }
  }
}
</script>

<style>
.table-container {
  display: flex;
  flex-flow: column nowrap;
}

.header-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 48px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.header-row:hover, .body-row:hover {
  border-bottom: solid 1px rgba(0, 0, 0, 0.24);
}

.header-row .table-element {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}

.little-space-teehee {
  width: 24px;
}

.table-element {
  flex: 1;
  flex-basis: 0;
  min-width: 0;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.87);
  white-space: normal;
}

.drag {
  flex-grow: 0.2;
}

.table-element-center {
  display: flex;
  justify-content: center;
}

.theme--light.v-treeview--hoverable .v-treeview-node__root:hover::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover::before {
  opacity: 0.04;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root:focus::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus::before {
  opacity: 0.12;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root--active:hover::before, .theme--light.v-treeview--hoverable .v-treeview-node__root--active::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active::before {
  opacity: 0.12;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root--active:focus::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::before, .theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0.12;
}
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-treeview .v-treeview-node--disabled > .v-treeview-node__root > .v-treeview-node__content {
  color: rgba(0, 0, 0, 0.38) !important;
}

.theme--dark.v-treeview {
  color: #FFFFFF;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root:hover::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover::before {
  opacity: 0.08;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root:focus::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus::before {
  opacity: 0.24;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:hover::before, .theme--dark.v-treeview--hoverable .v-treeview-node__root--active::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active::before {
  opacity: 0.24;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:focus::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::before, .theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0.24;
}
.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-treeview .v-treeview-node--disabled > .v-treeview-node__root > .v-treeview-node__content {
  color: rgba(255, 255, 255, 0.5) !important;
}

.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root,
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root:before {
  border-bottom-right-radius: 24px !important;
  border-top-right-radius: 24px !important;
}
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root,
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 24px !important;
}
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview-node--click > .v-treeview-node__root,
.v-treeview-node--click > .v-treeview-node__root > .v-treeview-node__content > * {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-treeview-node.v-treeview-node--active .v-treeview-node__content .v-icon {
  color: inherit;
}

.v-treeview-node__root {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
}
.v-treeview-node__root::before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-treeview-node__root::after {
  content: "";
  font-size: 0;
  min-height: inherit;
}

.v-treeview-node__children {
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.v-treeview--dense .v-treeview-node__root {
  min-height: 40px;
}
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root,
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root:before {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root,
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 20px !important;
}
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-treeview-node__checkbox {
  width: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-application--is-ltr .v-treeview-node__checkbox {
  margin-left: 6px;
}
.v-application--is-rtl .v-treeview-node__checkbox {
  margin-right: 6px;
}

.v-treeview-node__toggle {
  width: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-treeview-node__toggle--loading {
  -webkit-animation: progress-circular-rotate 1s linear infinite;
  animation: progress-circular-rotate 1s linear infinite;
}
.v-application--is-ltr .v-treeview-node__toggle {
  transform: rotate(-90deg);
}
.v-application--is-ltr .v-treeview-node__toggle--open {
  transform: none;
}
.v-application--is-rtl .v-treeview-node__toggle {
  transform: rotate(90deg);
}
.v-application--is-rtl .v-treeview-node__toggle--open {
  transform: none;
}

.v-treeview-node__prepend {
  min-width: 24px;
}
.v-application--is-ltr .v-treeview-node__prepend {
  margin-right: 6px;
}
.v-application--is-rtl .v-treeview-node__prepend {
  margin-left: 6px;
}

.v-treeview-node__append {
  min-width: 24px;
}
.v-application--is-ltr .v-treeview-node__append {
  margin-left: 6px;
}
.v-application--is-rtl .v-treeview-node__append {
  margin-right: 6px;
}

.v-treeview-node__level {
  width: 24px;
}

.v-treeview-node__label {
  flex: 1;
  font-size: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-treeview-node__content {
  align-items: center;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
}
.v-treeview-node__content .v-btn {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}
.v-application--is-ltr .v-treeview-node__content {
  margin-left: 6px;
}
.v-application--is-rtl .v-treeview-node__content {
  margin-right: 6px;
}
</style>
